/* ===================================
    01. Google font
====================================== */
:root {
    --alt-font: 'Plus Jakarta Sans', sans-serif;
    --primary-font: 'Inter', sans-serif;
}

/* ===================================
    02. CSS color variable
====================================== */
:root {
    --base-color: #2946f3;
    --white: #ffffff;
    --black: #000000;
    --red: #dc3131;
    --light-red: #feedec;
    --green: #2ebb79;
    --crusoe-green: #d39121;
    --yellow: #ffea23;
    --dark-gray: #232323;
    --medium-gray: #717580;
    --extra-medium-gray: #e4e4e4;
    --light-gray: #a8a8a8; 
    --very-light-gray: #f7f7f7;
    --light-medium-gray: #eaeaeb;
    --charcoal-blue: #202329;
    --slate-blue: #262b35; 
    --medium-slate-blue: #374162;
    --extra-medium-slate-blue: #23262d;
    --dark-slate-blue: #1f232c;
    --extra-dark-slate-blue: #121418;
    --extra-very-slate-blue: #161620;
    --tussock-yellow: #BC8947;
    --aluminium-grey:#80858F;
    --solitude-blue:#f0f4fd;
    --golden-yellow:#fd961e;
    --selago:#eaedff;
    --white-ice:#d8f5ef;
    --cornflower-blue:#445fed;
    --jade:#00AF6B;
    --orange: #ef991f;
    --majorelle-blue: #724ade;
    --light-majorelle-blue: #f2edfe;
    --spring-wood: #f9f6f3;
    --tropical-blue: #1ea3b1;
    --camarone: #20642b;
    --seal-brown: #0e0708;
    --Wasabi: #8ea63a;
}